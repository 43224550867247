import { Title, Container, ReportWrapper } from "./styles"
import Continue from '../../assets/images/continue.svg'

export const PendingReport = () => {
    return (
        <Container>
            <ReportWrapper>
                <img src={Continue} alt="" />
            </ReportWrapper>
            <Title>Su estudio está siendo revisado actualmente por un especialista. Le notificaremos en cuanto el mismo se encuentre informado.</Title> 
        </Container>
    )
}