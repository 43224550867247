import styled, { css } from 'styled-components'
import { colors } from '../../const'

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0.6rem;
  background-color: ${colors.lightgrey};
  border-top: 1px solid #dee2e6;
  min-height:6vh;
  min-width:100vw;
`

export const Logo = styled.img`
  height: 28px;
  margin-right:20px;
  margin-bottom:3px;
`

export const Version = styled.p`
  font-size: 0.9rem;
  align-self:center;
  color: #6c757d;
`