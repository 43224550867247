import styled, { css } from 'styled-components'
import ExternalLinkIcon from '../../assets/icons/external_link.png'
import { colors } from '../../const'

export const Container = styled.section`
  background-color: ${colors.white};
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.06),
    0px 0px 2px rgba(0, 0, 0, 0.04),
    0px 0px 5px rgba(0, 0, 0, 0.04),
    0px 0px 17px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  display: flex;
  margin-bottom: 2%;
  padding: 2%;
  width: 100%;
`

export const PatientNameImageExam = styled.div`
  border-right: 1px solid #D3DAE6;
  display: flex;
  justify-content: flex-start;
  padding-right: 5%;
  margin-right: 4%;
  width: 30%;
`

export const ImageWrapper = styled.div`
  align-items: center;
  background-color: #2687C5;
  border-radius: 50%;
  display: flex;
  height: min(5vw, 66px);
  justify-content: center;
  width: min(5vw, 66px);
`

export const Image = styled.img`
  height: min(3.3vw, 31px);
  width: min(3.3vw, 28px);
`

export const NameAndExamWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-left: 10%;
`

export const FullName = styled.h1`
  color: #000;
  font-family: 'Inter';
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  text-align: left;
  width: 100%;
`

export const ExamType = styled.h2`
  color: #282828;
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 400;
  margin: 3% 0 0;
  text-align: left;
  width: 100%;
`

export const PatientMultipleData = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 1%;
`

export const RowData = styled.div(
  ({ withBorder }) => css`
    border-bottom: 1px solid ${withBorder ? '#D3DAE6' : 'transparent'};
    display: flex;
    gap: 2%;
    justify-content: flex-start;
    padding: 1% 0;
    width: 100%;
  `
)

export const LabelAndInfoWrapper = styled.div`
  display: flex;
  padding: 0.5% 0;
  width: 28%;
`

export const DataLabel = styled.label`
  color: #0077CC;
  font-family: 'Inter';
  font-size: 0.875rem;
  font-weight: 600;
  margin-right: 10px;
`

export const DataInfo = styled.p`
  color: #808488;
  font-family: 'Inter';
  font-size: 0.875rem;
  margin: 0;
`

export const LinksWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 15%;
`

export const EditDataWrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`

export const EditDataText = styled.p`
  color: ${colors.primary};
  cursor: pointer;
  flex-grow: 1;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0 3% 0 0;
  text-align: right;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const EditDataIcon = styled.img`
  height: 13px;
  width: 13px;
`

export const InstructionsLink = styled.a`
  color: ${colors.primary};
  font-size: 0.8rem;
  font-weight: 400;
  margin-top: 5%;
  text-align: right;
  text-decoration: none;
  width: 100%;
  cursor:pointer;

  &::after {
    content: url(${ExternalLinkIcon});
    margin-left:5px;
    
  }

  &:hover {
    text-decoration: underline;
  }
`

export const Button = styled.button`
  align-items: center;
  background-color:rgba(0, 109, 228, 0.2);
  border: none;
  border-radius: 6px;
  color: #005EC4;
  cursor: pointer;
  display: flex;
  font-family: 'Inter';
  font-size: 0.875rem;
  font-weight: 500;
  height: 40px;
  justify-content: center;
  margin-right: 10px;
  margin-top: 20px;
  min-width: 112px;
  padding: 0 12px;
`
