import { useSelector } from 'react-redux';
import { URLS } from "../const/nav";

export const useOpenPDF = (url) => {
    const { user } = useSelector(state => state);

    const openPDF = async () => {
        try {
            const response = await fetch(`${URLS.BACKEND_URL}/${url}/`, {
                method: 'GET',
                headers: {
                    'Authorization': `${user.token}`, 
                },
            });

            if (response.status === 404) {
                // Manejar error 404 (No encontrado)
                console.log('PDF no encontrado.');
            } else if (response.ok) {
                const blob = await response.blob();
                const pdfUrl = window.URL.createObjectURL(blob);
                console.log('PDF abierto');
                window.open(pdfUrl, '_blank'); // Abre el PDF en una nueva pestaña
            } else {
                // Manejar otros errores
                console.error(`Error en la solicitud: ${response.status}`);
            } 
        } catch (error) {
            console.error('Error al realizar la solicitud:', error);
        }
    };

    return { openPDF };
}
