import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setResetCases } from '../../../redux/cases'
import { stepReset } from '../../../redux/steps'
import CheckIcon from '../../../assets/icons/check_bg_green.svg'
import {
  Container,
  SuccessImage,
  SuccessWrapper,
  SuccesImageWrapper,
  SuccessTitle,
  SubtitleSection,
  SuccessSubTitle,
  ButtonsWrapper,
  Button,
} from './styles'

export function CaseSended () {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleResetCase = () => {
    dispatch(setResetCases())
    dispatch(stepReset())
  }

  const handleGoToHome = () => {
    history.replace('/home')
    handleResetCase()
  }

  return (
    <Container>
      <SuccessWrapper>
        <SuccesImageWrapper>
          <SuccessImage src={CheckIcon} alt='success' />
        </SuccesImageWrapper>

        <SuccessTitle> Estudio referido a oftalmólogo </SuccessTitle>

        <SubtitleSection>
          <SuccessSubTitle>El estudio fue enviado con éxito a un oftalmólogo especialista en retina. El paciente recibirá en su correo electrónico las credenciales para monitorear el estado de su estudio. En cuanto el estudio sea informado, recibirá una notificación también por mail, y podrá acceder al informe profesional detallado.</SuccessSubTitle>
        </SubtitleSection>

        <ButtonsWrapper>
          <Button onClick={handleGoToHome}> Volver al inicio </Button>

          <Button highlighted onClick={handleResetCase}> Realizar nuevo estudio </Button>

        </ButtonsWrapper>
      </SuccessWrapper>
    </Container>
  )
}

export default CaseSended
