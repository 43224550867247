import styled, { css } from 'styled-components'
import { colors } from '../../../../../const'

const setColor = (error, attenuated) => {
  if(error) return colors.error
  if(attenuated) return colors.attenuated
}

const buttonColor = (available) => {
  if(available) return colors.primary
  return colors.secondary
}

export const SelectWrapper = styled.div`
  margin-bottom: 0px;
`


export const InputTemplate = styled.input(
  ({ error }) => css`
  background-color: #FBFCFD;
  border: ${error?`1px solid ${colors.error}`:`1px solid rgba(19, 34, 149, 0.1)`};
  border-bottom-width: 2px;
  border-radius: 6px;
  font-family: 'Inter';
  padding: 10px;
  position: relative;
  height: 40px;
  margin:0;

  &:focus {
    outline: none;
    background-color: ${colors.white};
    border-bottom-color: ${error?colors.error:colors.primary};
  }

  &[type="date"]::-webkit-calendar-picker-indicator {
    left: -15px;
    position: absolute;
  }
`)

export const Option= styled.option`
  margin-bottom:0px;
`

export const SelectTemplate = styled.select`
  background-color: #FBFCFD;
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-bottom-width: 2px;
  border-radius: 6px;
  font-family: 'Inter';
  padding: 10px;
  position: relative;
  height: 40px;
  margin-top:3px;
  cursor:pointer;
  
  &:focus {
    outline: none;
    background-color: ${colors.white};
    border-bottom-color: ${colors.primary};
  }
`

export const Container = styled.div`  
  background-color: ${colors.white};
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.07),
    0px 2px 4px rgba(0, 0, 0, 0.05),
    0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;
`

export const TitleWrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid #D3DAE6;
  display: flex;
  margin: 0 0 20px 0;
  padding: 20px 20px 6px 30px;
  gap:10px;
  width: 100%;
  height: 100%;
`

export const TittleContainer = styled.div`
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: space-between;
`

export const NewUserTitle = styled.h1`
  color: ${colors.lightBlack};
  font-family: 'Inter';
  font-size: 1.375rem;
  font-weight: 700;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 6px;
`

export const DniTitle = styled.p`
  color: ${colors.primary};
  font-family: 'Inter';
  font-size: 0.875rem;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
`

export const FillSectionWrapper = styled.div`
  display: flex;
  min-height: 300px;
  padding: 2% 5%;
  width: 100%;
`

export const FillSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
`

export const SeparatorFillSection = styled.div`
  display: flex;
  justify-content: center;
  width: 5%;

  &::after {
    background-color: rgba(211, 218, 230, 0.5);
    content: '';
    width: 1px;
  }
`

export const Label = styled.label(
  ({ error, attenuated}) => css`
  color: ${setColor(error, attenuated)};
  font-family: 'Inter';
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`)

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:focus-within {
    & label {
      color: ${colors.primary};
    }
  }
`

export const TwinInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const TwinInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  &:focus-within {
    & label {
      color: ${colors.primary};
    }
  }
`

export const SmallInput = styled(InputTemplate)`
  width: 100%;
`

export const RadioWrapper = styled.div`
  width: 100%;
`

export const RadioOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  height: 40px;
`

export const RadioInput = styled.input`
  margin: 4px 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const RadioLabel = styled.label`
  width: 33%;
`

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 30px 20px auto;
  max-width: 260px;
  width: 25%;
`

export const Button = styled.button(
  ({ highlighted, available }) => css`
    align-items: center;
    background-color: ${highlighted ? buttonColor(available) : colors.secondary};
    border: none;
    border-radius: 6px;
    color: ${highlighted ? colors.white : '#005EC4'};
    cursor: pointer;
    display: flex;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    max-width: 115px;
    width: 100%;
  `
)
