import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { stepDecrement } from '../../redux/steps'
import CancelCaseModal from './CancelCaseModal'
import { useModal } from '../../hooks/useModal'
import CheckIcon from '../../assets/icons/small_check.svg'
import { useState } from 'react'
import {
  Container,
  ContainerStepperInfo,
  CaseNumberTitle,
  CaseNumberCounter,
  CancelCase,
  StepperWrapper,
  Step,
  Connector,
  Icon,
  StepperContainer
} from './styles'

const STEPS = [
  { id: 1, description: 'Identificación de paciente' },
  { id: 2, description: 'Carga de nueva consulta' },
  { id: 3, description: 'Carga de imágenes' },
  { id: 4, description: 'Diagnóstico por IA' }
]

export function Stepper () {
  const { user } = useSelector(state => state)
  console.log(user)
  const [CASE_NUMBER_MOCKED] = useState(user && user.evaluations.length + 1)
  const { current: currentStep, completed: caseCompleted } = useSelector(state => state.steps)
  const dispatch = useDispatch()
  const [modalRef, openModal, closeModal] = useModal()

  const handleStepClick = ({step}) => {
    if (currentStep === 1 || step.id >= currentStep || currentStep === 3 || currentStep === 4) {
      return
    } else {
      for(let i = currentStep; i > step.id; i--){
        dispatch(stepDecrement())
      }
    }
  }

  return (
    <Container>
      <CancelCaseModal
        ref={modalRef}
        close={closeModal}
      />

      <ContainerStepperInfo>
        <CaseNumberTitle> Nuevo estudio </CaseNumberTitle>
        <CaseNumberCounter> n° {CASE_NUMBER_MOCKED} </CaseNumberCounter>
      </ContainerStepperInfo>

      <StepperContainer>
      <StepperWrapper>
        {STEPS.map((step, index) => (
          <React.Fragment key={step.id}>
            <Step active={step.id === currentStep} completed={currentStep > step.id} clickeable={currentStep !== 3 && currentStep !== 4} text={step.description} onClick={()=>handleStepClick({step})}>
              {currentStep > step.id ? <Icon src={CheckIcon} alt='check' /> : step.id}
            </Step>
            {index < STEPS.length - 1 && <Connector />}
          </React.Fragment>
        ))}
      </StepperWrapper>
      </StepperContainer>

      {!caseCompleted && <ContainerStepperInfo> <CancelCase onClick={openModal}> Cancelar estudio </CancelCase> </ContainerStepperInfo>}

    </Container>
  )
}

export default Stepper
