import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { modifyLeftEyeCurrentImage, modifyRightEyeCurrentImage } from '../../../redux/images'
import RemoveImageModal from './RemoveImageModal'
import { useModal } from '../../../hooks/useModal'
import AddImageIcon from '../../../assets/icons/add_image.svg'
import RemoveImageIcon from '../../../assets/icons/trash.svg'
import useImagesSelector from '../../../hooks/useImagesSelector'
import { ImageFullScreen } from './ImageFullScreen'
import { ThumbnailImg } from '../Thumbnail'
import {
  Container,
  PrincipalImageInfo,
  ImagesContainer,
  ThumbnailsContainer,
  ThumbnailWrapper,
  ThumbnailCounterWrapper,
  Icon,
  IconTrash,
  PrincipalImageContainer,
  PrincipalImageShadow,
  UploadImageButton,
  UploadImageFooter,
  HiddenInputFile,
  PrincipalImageText
} from './styles'

export function ImagesUpload ({ study, whichEye, isVisible }) {
  const { leftEye, rightEye } = useSelector(state => state.images)
  const dispatch = useDispatch()
  const EYE_REFERENCE = {
    left: {
      currentImage: leftEye.currentImage,
      setCurrentImage: modifyLeftEyeCurrentImage,
      images: leftEye.images
    },
    right: {
      currentImage: rightEye.currentImage,
      setCurrentImage: modifyRightEyeCurrentImage,
      images: rightEye.images
    },
  }
  const { currentImage: currentSelectedImage, images } = EYE_REFERENCE[whichEye]

  const [modalRef, openModal, closeModal] = useModal()
  const {
    inputFileRef,
    handleOpenFileExplorer,
    handleRestoreImages,
    handleUploadImage,
    handleDropImages,
    handleDragImages,
    handleRemoveImage
  } = useImagesSelector({ whichEye })

  const confirmRemoveImage = () => {
    closeModal()
    handleRemoveImage()
  }

  const handleSelectPrincipalImage = (thumbnail) => {
    const { setCurrentImage } = EYE_REFERENCE[whichEye]
    dispatch(setCurrentImage(thumbnail))
  }

  const handleSelectActionImage = (action) => {
    switch (action) {
      case 'show':
        setModalType('show');
        openModal();
        break;
      case 'delete':
        setModalType('delete');
        openModal();
        break;
      default:
        break;
    }

  }

  useEffect(() => {
    handleRestoreImages(study)
  }, [])


  return (
    <Container isVisible={isVisible}>
      <RemoveImageModal
        ref={modalRef}
        position={currentSelectedImage?.position}
        close={closeModal}
        confirm={confirmRemoveImage}
      />

    <PrincipalImageInfo display={currentSelectedImage}>
      {currentSelectedImage 
        ? <>
          <PrincipalImageText>
            {currentSelectedImage.name}
          </PrincipalImageText>
          <IconTrash title='Eliminar imagen actual' src={RemoveImageIcon} alt='delete' onClick={openModal} />
        </>
        : null}
    </PrincipalImageInfo>

      <ImagesContainer>
        <ThumbnailsContainer>
          {images.map((thumbnail) => (
            <ThumbnailCounterWrapper key={thumbnail.position}>
              <ThumbnailWrapper 
                image={thumbnail.image}
                status={thumbnail.status}
                selected={currentSelectedImage?.position === thumbnail.position}
                onClick={thumbnail.image ? () => handleSelectPrincipalImage(thumbnail) : handleOpenFileExplorer}
              >
                {thumbnail.image
                  ? <ThumbnailImg image={thumbnail.image} status={thumbnail.status}/>
                  : <Icon src={AddImageIcon} alt='add-image' />}
              </ThumbnailWrapper>
            </ThumbnailCounterWrapper>
          ))}
        </ThumbnailsContainer>

        <PrincipalImageContainer inTheCenter={currentSelectedImage} onDragOver={handleDragImages} onDrop={handleDropImages}>
          {currentSelectedImage && (
            <ImageFullScreen whichEye={whichEye} currentSelectedImage={currentSelectedImage}/>
          )}

          {!currentSelectedImage && (
            <PrincipalImageShadow>
              <UploadImageButton onClick={handleOpenFileExplorer}>
                Subir imágenes
              </UploadImageButton>
              <UploadImageFooter> O soltar los archivos aquí </UploadImageFooter>
            </PrincipalImageShadow>
          )}
        </PrincipalImageContainer>

        <HiddenInputFile
          type='file'
          multiple
          accept='.png, .jpg, .jpeg'
          ref={inputFileRef}
          onChange={handleUploadImage}
        />
      </ImagesContainer>
    </Container>
  )
}

export default ImagesUpload
