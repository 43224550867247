import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { URLS } from './const/nav'

var apiUrl = window.location.origin;
URLS.BACKEND_URL = apiUrl+'/api';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
