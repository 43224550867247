export const colors = {
  primary: '#0071C2',
  secondary: '#006DE433',
  attenuated: '#8f8f8f',
  white: '#FFFFFF',
  lightBlack: '#343741',
  darkBlack: '#1A1C21',
  unavailable: '#737373',
  background: '#F5F5F5',
  success: '#4CAF50',
  error: '#F44336',
  warning: '#FFC107',
  lightgrey: '#c1c0b9',
}
