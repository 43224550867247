import { useEffect } from 'react'
import { useSearchPatient } from '../../../../../hooks/NewCase/useSearchPatient'
import { useState } from 'react'
import { postPatient, updatePatient } from '../../../../../hooks/NewCase/usePostPatient'
import { SessionExpired } from '../../../../SessionExpired'
import { useRequiered } from '../../../../../hooks/NewCase/Requiered/useRequiered'
import {
  Container,
  TitleWrapper,
  NewUserTitle,
  DniTitle,
  FillSectionWrapper,
  FillSection,
  SeparatorFillSection,
  InputWrapper,
  Label,
  InputTemplate,
  SelectTemplate,
  SelectWrapper,
  TwinInputContainer,
  TwinInputWrapper,
  SmallInput,
  RadioWrapper,
  RadioOptions,
  RadioInput,
  Option,
  RadioLabel,
  ButtonsWrapper,
  TittleContainer,
  Button
} from './styles'

export function PhaseOne({ backStep, nextPhase }) {
  const { patient, patientData, search, error } = useSearchPatient()
  const [noSocialSecurity, setNoSocialSecurity] = useState(true)
  const { isValid, setPatientData, dataError, checkRequired, buttonAvailable } = useRequiered("PhaseOne")
  const [data, setData] = useState({
    dni: patient ? patient.dni : '',
    genero: patient ? patient.genero : '',
    nombre: '',
    apellido: '',
    fechaNacimiento: '',
    telefono: '',
    domicilio: '',
    piso: '',
    departamento: '',
    email: '',
    localidad: '',
    provincia: '',
    tipoSeguridadSocial: 'Ninguna',
    codigoAfiliado: '',
  })

  const provincias = [
    "Buenos Aires", "Catamarca", "Chaco", "Chubut", "Córdoba", "Corrientes", "Entre Ríos",
    "Formosa", "Jujuy", "La Pampa", "La Rioja", "Mendoza", "Misiones", "Neuquén",
    "Río Negro", "Salta", "San Juan", "San Luis", "Santa Cruz", "Santa Fe", "Santiago del Estero",
    "Tierra del Fuego", "Tucumán"
  ];

  const savePatient = async () => {
    try {
      checkRequired(data, noSocialSecurity)
    } catch (error) {
      console.log(error)
    }
  }

  console.log(data.tipoSeguridadSocial)
  function formatearFechaNacimiento(fechaNacimiento) {
    const fecha = new Date(fechaNacimiento);
    const formattedDate = fecha.toISOString().split('T')[0];
    return formattedDate;
  }

  useEffect(() => {
    const next = async () => {
      if (isValid) {
        if (patientData) {
          await updatePatient(data, patientData.id).then(() => nextPhase())
          sessionStorage.setItem('_patient', JSON.stringify(patientData))
        } else {
          console.log('publicando')
          await postPatient(data).then(() => nextPhase())
          sessionStorage.setItem('_patient', JSON.stringify(data))
        }

      } else {
        return
      }
    }
    next()
  }, [isValid])

  useEffect(() => {
    const fetchData = async () => {
      await search()
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (patientData) {
      setPatientData(patientData)
      setData(patientData)
      setNoSocialSecurity(patientData && patientData.tipoSeguridadSocial === 'Ninguna')
    }
  }, [patientData])

  const changeHandler = event => {
    if (event.target.name === 'tipoSeguridadSocial') {
      console.log(event.target.value)
      event.target.value === 'Ninguna' ? setNoSocialSecurity(true) : setNoSocialSecurity(false);
    }
    updateData(event.target.name, event.target.value);
  }

  const updateData = (name, value) => {
    setData(prevData => {
      const newData = { ...prevData, [name]: value };
      checkRequired(newData, noSocialSecurity, true);
      return newData;
    });
  }

  useEffect(() => {
    updateData('tipoSeguridadSocial', data.tipoSeguridadSocial);
  }, [noSocialSecurity]);


  return (
    <Container>
      <SessionExpired />
      <TitleWrapper>
        <TittleContainer>
          <NewUserTitle> {error && error ? 'Nuevo Paciente' : 'Modificar Paciente'} </NewUserTitle>
          <DniTitle> DNI {patient && patient.dni} </DniTitle>
        </TittleContainer>
      </TitleWrapper>

      <FillSectionWrapper>
        <FillSection>
          <TwinInputContainer>
            <TwinInputWrapper>
              <Label> Nombre </Label>
              <SmallInput error={dataError && dataError.nombre} onChange={changeHandler} name='nombre' defaultValue={patientData ? patientData.nombre : ''} />
            </TwinInputWrapper>

            <TwinInputWrapper>
              <Label> Apellido </Label>
              <SmallInput error={dataError && dataError.apellido} onChange={changeHandler} name='apellido' defaultValue={patientData ? patientData.apellido : ''} />
            </TwinInputWrapper>
          </TwinInputContainer>

          <TwinInputContainer>
            <TwinInputWrapper>
              <Label> Fecha de nacimiento </Label>
              <InputTemplate error={dataError && dataError.fechaNacimiento} onChange={changeHandler} name='fechaNacimiento' type='date' defaultValue={patientData ? formatearFechaNacimiento(patientData.fechaNacimiento) : ''} />
            </TwinInputWrapper>
            <TwinInputWrapper>
              <Label> Telefono </Label>
              <SmallInput error={dataError && dataError.telefono} onChange={changeHandler} name='telefono' defaultValue={patientData ? patientData.telefono : ''} />
            </TwinInputWrapper>
          </TwinInputContainer>

          <InputWrapper>
            <Label> Correo electrónico </Label>
            <InputTemplate error={dataError && dataError.email} onChange={changeHandler} name='email' defaultValue={patientData ? patientData.email : ''} />
          </InputWrapper>
        </FillSection>

        <SeparatorFillSection />

        <FillSection>

          <InputWrapper>
            <Label> Domicilio </Label>
            <InputTemplate error={dataError && dataError.domicilio} onChange={changeHandler} name='domicilio' defaultValue={patientData ? patientData.domicilio : ''} />
          </InputWrapper>

          <TwinInputContainer>
            <TwinInputWrapper>
              <Label>
                <Label> Piso </Label>
                <Label attenuated> (Opcional) </Label>
              </Label>

              <SmallInput onChange={changeHandler} name='piso' defaultValue={patientData ? patientData.piso : ''} />
            </TwinInputWrapper>

            <TwinInputWrapper>
              <Label>
                <Label> Departamento </Label>
                <Label attenuated> (Opcional) </Label>
              </Label>
              <SmallInput onChange={changeHandler} name='departamento' defaultValue={patientData ? patientData.departamento : ''} />
            </TwinInputWrapper>
          </TwinInputContainer>

          <TwinInputContainer>
            <TwinInputWrapper>
              <SelectWrapper>
                <Label>Provincia</Label>
                <SelectTemplate name='provincia' value={data.provincia} onChange={changeHandler}>
                  <Option value="" disabled>Seleccionar</Option>
                  {provincias.map((provincia) => (
                    <option key={provincia} value={provincia}>
                      {provincia}
                    </option>
                  ))}
                </SelectTemplate>
              </SelectWrapper>
            </TwinInputWrapper>
            <TwinInputWrapper>
              <Label> Localidad </Label>
              <InputTemplate error={dataError && dataError.localidad} onChange={changeHandler} name='localidad' defaultValue={patientData ? patientData.localidad : ''} />
            </TwinInputWrapper>
          </TwinInputContainer>
        </FillSection>

        <SeparatorFillSection />

        <FillSection>
          <RadioWrapper>
            <Label error={dataError && dataError.tipoSeguridadSocial} > Seguridad social </Label>
            <RadioOptions>
              <RadioLabel>
                <RadioInput onChange={changeHandler} name='tipoSeguridadSocial' checked={data.tipoSeguridadSocial === 'Obra Social'} type='radio' value='Obra Social' />
                Obra Social
              </RadioLabel>

              <RadioLabel>
                <RadioInput onChange={changeHandler} name='tipoSeguridadSocial' checked={data.tipoSeguridadSocial === 'Prepaga'} type='radio' value='Prepaga' />
                Prepaga
              </RadioLabel>

              <RadioLabel>
                <RadioInput onChange={changeHandler} name='tipoSeguridadSocial' checked={noSocialSecurity || (data.tipoSeguridadSocial === 'Ninguna')} type='radio' value='Ninguna' />
                Ninguna
              </RadioLabel>
            </RadioOptions>
          </RadioWrapper>

          {/* <InputWrapper>
            <SelectTemplate />
          </InputWrapper> */}

          {!noSocialSecurity && <InputWrapper>
            <Label> Código de afiliado </Label>
            <InputTemplate error={dataError && dataError.codigoAfiliado} onChange={changeHandler} name='codigoAfiliado' defaultValue={patientData ? patientData.codigoAfiliado : ''} />
          </InputWrapper>}
        </FillSection>
      </FillSectionWrapper>

      <ButtonsWrapper>
        <Button onClick={backStep}> Volver </Button>
        <Button highlighted available={buttonAvailable || patientData} onClick={savePatient}> Siguiente </Button>
      </ButtonsWrapper>
    </Container>
  )
}

export default PhaseOne
